import ParticlesBg from "particles-bg";

export const Header = (props) => {
  document.querySelector('.submit-email')?.addEventListener('mousedown', async (e) => {
    e.preventDefault();
    for (const el of document.getElementById('signup-form').querySelectorAll("[required]")) {
      if (!el.reportValidity()) {
        return;
      }
    }
    const email = document.querySelector('.add-email').value;
    await fetch('https://api.coachforce.ai/subscribe', {
      method: 'POST',
      headers: {'Content-Type': 'application/json',},
      body: JSON.stringify({"email": email}),
    });
    document.querySelector('.subscription').classList.add('done');
  });


  return (
    <header id='header'>
      <div className='intro'>
        <ParticlesBg type="cobweb" bg={{zIndex: 0, position:"absolute", top:0}} color="#1111AA" num={100} />
        <div className='overlay'>
          <div className='container'>

            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <div className="subscription-container" id='signup-form' >
                  <div className="subscription-content">
                    <form className="subscription">
                      <input className="add-email" type="email" placeholder="Your email" required/>
                      <button className="submit-email" type="button">
                        <span className="before-submit">Sign Up</span>
                        <span className="after-submit">Thank you for signing up!</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
